import { useDebounceFn, useMount } from 'ahooks';
import { debounce, isNil } from 'lodash-es';
import { useEffect, useLayoutEffect, useMemo } from 'react';
import uaParser from 'ua-parser-js';
import { create } from 'zustand';

import type { FormLayout } from 'antd/es/form/Form.d.ts';
import { persist } from 'zustand/middleware';

interface UserAgentType {
	userAgent?: uaParser.IResult;
	setUserAgent: (input: uaParser.IResult) => void;
	size: [number, number];
	setSize: (input: [number, number]) => void;
}
const useUserAgentStore = create(
	persist<UserAgentType>(
		(set) => ({
			userAgent: undefined,
			setUserAgent: (input) => set(() => ({ userAgent: input })),
			size: [0, 0],
			setSize: (input) => set(() => ({ size: input })),
		}),
		{
			name: 'userAgent-storage', // name of the item in the storage (must be unique)
		},
	),
);

export const useUpdateGlobalUserAgent = () => {
	const setUserAgent = useUserAgentStore((state) => state.setUserAgent);
	const setSize = useUserAgentStore((state) => state.setSize);
	useMount(() => {
		setUserAgent(uaParser(navigator.userAgent));
	});

	useEffect(() => {
		const updateSize = debounce(() => {
			setSize([window.innerWidth, window.innerHeight]);
			// console.log(window.innerWidth, window.innerHeight)
		}, 500);

		window.addEventListener('resize', updateSize);
		updateSize();
		return () => window.removeEventListener('resize', updateSize);
	}, [setSize]);
};

export const useUserAgent = () => {
	const userAgent = useUserAgentStore((state) => state.userAgent);
	const size = useUserAgentStore((state) => state.size);
	const isMacOS = 'Mac OS' === userAgent?.os.name;
	const isWindow = userAgent?.os.name?.includes('Windows');
	const isMobile = size[0] < 900;
	const isPC = !isMobile;

	const formLayout: FormLayout = isPC ? 'inline' : 'vertical';

	return {
		formLayout,
		isMobile,
		isPC,
		isMacOS,
	};
};
